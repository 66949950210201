<template>
	<header class="active">
		<div class="grid">

			<RouterLink to="/" class="logo">
				<IconLogo />
			</RouterLink>
			<nav>
				<RouterLink v-for="link in nav" :to=link.route>{{ link.name }}</RouterLink>
			</nav>

			<RouterLink to="/account" class="btn--dark">
				<template v-if="!store.isAuth">Login</template><template v-else>{{ store.username }}
					<IconArrow />
				</template>
			</RouterLink>

			<div id="burger" @click="showMobileMenu = !showMobileMenu">
				<span></span><span></span><span></span>
			</div>

		</div>
	</header>
	<div id="mobile-menu" v-if="showMobileMenu">
		<div class="grid">
			<nav>
				<RouterLink v-for="link in nav" :to=link.route>{{ link.name }}</RouterLink>
			</nav>

			<RouterLink to="/account" class="btn">
				<template v-if="!store.isAuth">Login</template><template v-else>{{ store.username }}
					<IconArrow />
				</template>
			</RouterLink>
		</div>
	</div>
</template>


<script setup lang="ts">
import { usePlatformStore } from '~/store/platform'

const store = usePlatformStore()
const api = "http://flirtverse-api.ravespace.io/api/"

const showMobileMenu = ref(false)
const nav = ref([
	{ route: "/templates", name: "Templates" },
	{ route: "/pricing", name: "Pricing" },
	{ route: "/toolkit", name: "Toolkit" },
	{ route: "/artist/dion", name: "Featured" }
])

onMounted(async () => {
	if (localStorage.getItem("token")) {
		const token = localStorage.getItem("token")
		if (token) store.authToken = token
		store.isAuth = true
	}
	if (localStorage.getItem("username")) {
		const username = localStorage.getItem("username")
		if (username) store.username = username
		store.isAuth = true
	}
	if (localStorage.getItem("userid")) {
		const userid = localStorage.getItem("userid")
		if (userid) store.userId = userid
	}

});



</script>

<style lang="scss" scoped>
@import "assets/css/platform.scss";

header {
	position: fixed;
	left: 0;
	top: 20px;
	width: 100%;
	z-index: 99;

	.grid {
		border-radius: 7px;
		background: $main;
		box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.25);
		backdrop-filter: blur(2px);
		height: 60px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 20px;

		nav {
			a {
				text-decoration: none;
				font-weight: 500;
				margin: 0 10px;
				font-size: 14px;

				&:hover {
					opacity: 0.5;
				}
			}

			.router-link-active {
				border-bottom: 1px solid $dark;
			}
		}

		.btn {
			svg {
				width: 18px;
				transform: translateY(-0.5px);
				height: auto;
			}
		}

		.logo {
			svg {
				width: 90px;
				height: auto;

				&::v-deep(*) {
					fill: $dark;
				}
			}
		}

		.btn {
			padding: 8px 17px;
			font-size: 13px;
		}
	}
}

#mobile-menu {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 89;
	background: $main;
	width: 100%;
	height: 100%;

	.grid {
		padding-top: 100px;
		@extend .flex-center;
		flex-direction: column;
		width: 100%;

		nav {
			@extend .flex-center;
			flex-direction: column;
			width: 100%;

			a {
				padding: 10px 0;
				font-weight: 700;
				text-decoration: none;
				width: 100%;
				border-bottom: 1px solid #0000002b;
				text-align: center;
			}
		}

		.btn {
			background: $dark;
			margin-top: 20px;
		}
	}
}

#burger {
	width: 30px;
	height: 30px;
	@extend .flex-center;
	flex-direction: column;
	cursor: pointer;
	display: none;

	span {
		width: 100%;
		height: 5px;
		background: $dark;
		display: inline-block;
		margin: 4px 0;
	}
}

.user-wrap {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	background: white;
	padding: 5px 10px;
	border-radius: 7px;
	box-shadow: 0 0px 5px #7467a029;

	p {
		font-size: 13px;
		font-weight: 500;
		color: $dark;
	}
}

@media(max-width:800px) {
	header {
		.grid {

			nav,
			.btn {
				display: none;
			}

			#burger {
				display: flex;
			}
		}
	}
}
</style>
